import { Table } from "antd";

const ProductsTable = ({ data }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price"
    }
  ];

  return (
    <>
      <Table rowKey="id" dataSource={data} columns={columns} />
    </>
  );
};

export default ProductsTable;
