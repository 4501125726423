import { auth } from "../../firebase";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser !== null) {
      navigate("/labs", { replace: true });
    }
  }, [currentUser, navigate]);

  return (
    <div style={{ maxWidth: "300px", margin: "0 auto" }}>
      <h1>Login</h1>
      <Button
        loading={loading}
        onClick={() => {
          setLoading(true);
          const provider = new GoogleAuthProvider();
          return signInWithPopup(auth, provider)
            .then(user => {
              setLoading(false);
              console.log("Google sign-in result:", user);
              navigate("/users", { replace: true });
            })
            .catch(error => {
              console.error("Google sign-in error:", error);
              setLoading(false);
            });
        }}
      >
        Sign in with Google
      </Button>
    </div>
  );
};

export default Login;
