import { useEffect, useState } from "react";
import { get } from "../api";
import MyPageHeader from "../components/layout/MyPageHeader";
import ValueLabel from "../components/layout/ValueLabel";
import { formatDate } from "../helpers";
import { useParams } from "react-router-dom";
import { Skeleton, Spin } from "antd";
import { Column } from "@ant-design/plots";
import UsersInLabTable from "../components/UsersInLabTable";
import ProductsTable from "../components/ProductsTable";
import DoctorsTable from "../components/DoctorsTable";

const CasesColumn = ({ data }) => {
  const config = {
    data,
    xField: "month",
    yField: "caseCount",
    label: {
      text: d => `Cases ${d.caseCount}`,
      textBaseline: "bottom"
    },
    style: {
      radiusTopLeft: 10,
      radiusTopRight: 10
    }
  };
  return <Column {...config} />;
};

const Lab = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  const fetchData = () => {
    get(`labs/${id}`)
      .then(response => {
        setData(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      <MyPageHeader key="ph" title={"Lab Profile"} />
      <Skeleton loading={!data}>
        {data?.lab && (
          <>
            <ValueLabel label="Name">{data.lab.name}</ValueLabel>
            <ValueLabel label="Currency">{data.lab.currency}</ValueLabel>
            <ValueLabel label="Created">
              {formatDate(data.lab.created)}
            </ValueLabel>
          </>
        )}
        {data?.users && (
          <>
            <h2>Users</h2>
            <UsersInLabTable data={data.users} />
          </>
        )}
        {data?.doctors && (
          <>
            <h2>Doctors</h2>
            <DoctorsTable data={data.doctors} />
          </>
        )}
        {data?.products && (
          <>
            <h2>Products</h2>
            <ProductsTable data={data.products} />
          </>
        )}
        {data?.cases && (
          <>
            <h2>Cases</h2>
            <CasesColumn data={data.cases} />
          </>
        )}
      </Skeleton>
    </>
  );
};

export default Lab;
