import { useEffect, useState } from "react";
import { Table, Skeleton, Button } from "antd";
import { get } from "../api";
import MyPageHeader from "../components/layout/MyPageHeader";
import { formatDate } from "../helpers";
import { useNavigate } from "react-router-dom";

const Cases = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  useEffect(() => {
    fetchData();
  }, [pagination.current]);

  const fetchData = () => {
    setLoading(true);
    get("cases", pagination)
      .then(response => {
        setData(response.results);
        setPagination(p => ({
          ...p,
          total: response.totalCount
        }));
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleTableChange = pagination => {
    setPagination(pagination);
  };

  const columns = [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: date => {
        return formatDate(date);
      }
    },
    {
      title: "Case Number",
      dataIndex: "caseNumber",
      key: "caseNumber",
      render: text => <p className="table-main">{text}</p>
    },
    {
      title: "Doctor",
      dataIndex: "doctorName",
      key: "doctorName"
    },
    {
      title: "Patient",
      dataIndex: "patient",
      key: "patient"
    },
    {
      title: "Lab",
      dataIndex: "labName",
      key: "labName",
      render: (text, record) => (
        <>
          <Button onClick={() => navigate("/lab/" + record.labId)}>
            {text}
          </Button>
        </>
      )
    },
    {
      title: "User",
      dataIndex: "userName",
      key: "userName",
      render: (text, record) => (
        <>
          <Button onClick={() => navigate("/user/" + record.email)}>
            {text}
          </Button>
        </>
      )
    }
  ];

  return (
    <>
      <MyPageHeader key="ph" title={"Cases"} />
      <Skeleton loading={loading}>
        <Table
          rowKey="id"
          dataSource={data}
          columns={columns}
          pagination={{
            ...pagination,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
          }}
          onChange={handleTableChange}
        />
      </Skeleton>
    </>
  );
};

export default Cases;
