import { useEffect, useState } from "react";
import { get } from "../api";
import MyPageHeader from "../components/layout/MyPageHeader";
import ValueLabel from "../components/layout/ValueLabel";
import { formatDate } from "../helpers";
import { useParams } from "react-router-dom";

const UserProfile = () => {
  const { email } = useParams();
  const [data, setData] = useState(null);

  const fetchData = () => {
    get("users/byEmail", {
      email: email
    })
      .then(response => {
        setData(response);
        console.log(response, "response");
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [email]);

  return (
    <>
      <MyPageHeader key="ph" title={"User Profile"} />

      {data && (
        <>
          {data.databaseData && (
            <>
              <h2>DB Data</h2>
              <ValueLabel label="User ID">{data.databaseData.id}</ValueLabel>
              <ValueLabel label="Email">{data.databaseData.email}</ValueLabel>
              <ValueLabel label="Firebase ID">
                {data.databaseData.firebaseId}
              </ValueLabel>
              <ValueLabel label="Created">
                {formatDate(data.databaseData.created)}
              </ValueLabel>
            </>
          )}
          {data.userAuth && (
            <>
              <h2>Firebase Auth</h2>
              <ValueLabel label="Name">{data.userAuth.displayName}</ValueLabel>

              <ValueLabel label="uid">{data.userAuth.uid}</ValueLabel>
              <ValueLabel label="Email">{data.userAuth.email}</ValueLabel>
              <ValueLabel label="Email Verified">
                {data.userAuth.emailVerified ? "Yes" : "No"}
              </ValueLabel>
              <ValueLabel label="Disabled">
                {data.userAuth.disabled ? "Yes" : "No"}
              </ValueLabel>
              <ValueLabel label="Last sign in">
                {formatDate(data.userAuth.metadata?.lastSignInTime)}
              </ValueLabel>
              {data.userAuth.providerData.map(provider => (
                <ValueLabel label="Provider">{provider.providerId}</ValueLabel>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default UserProfile;
