import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavBar } from "antd-mobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "../../context/GlobalContext";
import { Button, Space } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const MyPageHeader = ({ ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { deviceType } = useContext(GlobalContext);

  if (props.hidden) return <></>;
  if (deviceType === "mobile") {
    return (
      <div className="top">
        <NavBar
          backArrow={
            location.key || props.backUrl !== undefined ? (
              props.customBackText !== undefined ? (
                <span style={{ fontSize: 14 }}>{props.customBackText}</span>
              ) : (
                <FontAwesomeIcon icon={faChevronLeft} />
              )
            ) : (
              ""
            )
          }
          onBack={() => {
            if (props.backUrl?.length) navigate(props.backUrl);
            else navigate(-1);
            // navigate.goBack();
          }}
          right={props.extra}
        >
          {props.title}
        </NavBar>
        {/* {props.extra && <div className="scroll">{props.extra}</div>} */}
      </div>
    );
  }
  return (
    <>
      <div className="ph">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={() => window.history.back()}
            style={{ marginRight: "16px" }}
          />
          <h1>{props.title}</h1>
        </div>
        <Space>{props.extra}</Space>
      </div>
    </>

    // className={navigate.action === "POP" ? "hide_ph_back" : ""}
    // className={navigate.action === "POP" ? "hide_ph_back" : ""}
  );
};

export default MyPageHeader;
