import { Input } from "antd";
import { get } from "../api";
import MyPageHeader from "../components/layout/MyPageHeader";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

const FindFirebaseUser = () => {
  const navigate = useNavigate();

  const fetchData = (searchText) => {
    get("users/byEmail", {
      email: searchText,
    })
      .then((response) => {
        if (response.userAuth.uid) navigate(`/user/${response.userAuth.email}`);
        else alert("User not found");
      })
      .catch((error) => {
        alert("User not found");
      });
  };

  return (
    <>
      <MyPageHeader key="ph" title={"Find User Firebase and DB data"} />
      <Search
        placeholder="Search users by email"
        enterButton="Search"
        size="large"
        onSearch={(value) => {
          fetchData(value);
        }}
      />
    </>
  );
};

export default FindFirebaseUser;
