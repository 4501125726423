import { useEffect, useState } from "react";
import { Table, Input, Button, Space, Skeleton } from "antd";
import { get } from "../api";
import MyPageHeader from "../components/layout/MyPageHeader";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../helpers";
import debounce from "lodash/debounce";

const { Search } = Input;

const Users = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  useEffect(() => {
    fetchData();
  }, [pagination.current, searchText]);

  const fetchData = () => {
    setLoading(true);
    get("users/search", {
      pageSize: pagination.pageSize,
      current: pagination.current,
      search: searchText
    })
      .then(response => {
        setData(response.results);
        setPagination({
          ...pagination,
          total: response.totalCount
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleTableChange = pagination => {
    setPagination(pagination);
  };

  const columns = [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: date => {
        return formatDate(date);
      }
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: text => <p className="table-main">{text}</p>
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: text => <p className="table-main">{text}</p>
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: text => <p className="table-main">{text}</p>
    },
    {
      title: "",
      key: "actions",
      render: (text, record) => (
        <>
          <Button onClick={() => navigate("/user/" + record.email)}>
            View
          </Button>
        </>
      )
    }
  ];

  return (
    <>
      <MyPageHeader key="ph" title={"Users"} />
      <Space.Compact block>
        <Search
          placeholder="Search users by email"
          onChange={debounce(e => {
            setPagination(p => ({ ...p, current: 1 }));
            setSearchText(e.target.value);
          }, 300)}
        />
      </Space.Compact>
      <Skeleton loading={loading}>
        <Table
          rowKey="id"
          dataSource={data}
          columns={columns}
          pagination={{
            ...pagination,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
          }}
          onChange={handleTableChange}
        />
      </Skeleton>
    </>
  );
};

export default Users;
