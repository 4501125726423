import { Layout } from "antd";
import NavRoutes from "../../nav/Routes";
import { Menu } from "antd";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import Logo from "../../nav/Logo";
import {
  AuditOutlined,
  HomeOutlined,
  LogoutOutlined,
  UserOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
const { Content, Footer, Sider } = Layout;

function AppContent() {
  const { deviceType } = useContext(GlobalContext);
  const { currentUser } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    {
      label: "Labs",
      onClick: () => {
        navigate("/labs");
      },
      icon: <HomeOutlined />
    },
    {
      label: "Users",
      onClick: () => {
        navigate("/users");
      },
      icon: <UsergroupAddOutlined />
    },
    {
      label: "Find User",
      onClick: () => {
        navigate("/fb-users");
      },
      icon: <UserOutlined />
    },
    {
      label: "Cases",
      onClick: () => {
        navigate("/cases");
      },
      icon: <AuditOutlined />
    },
    {
      label: "Logout",
      onClick: () => {
        navigate("/logout");
      },
      icon: <LogoutOutlined />
    }
  ];

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={collapsed}
        onBreakpoint={() => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type, "collapsed");
          setCollapsed(collapsed);
        }}
        hidden={!currentUser}
      >
        <Logo />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={menuItems.map((i, index) => ({
            key: String(index + 1),
            label: i.label,
            icon: i.icon,
            onClick: () => {
              console.log(deviceType, "dt");
              if (deviceType === "mobile") setCollapsed(true);
              i.onClick();
            },
            children:
              i.children &&
              i.children.map((c, cindex) => ({
                key: index + "-" + String(cindex + 1),
                label: c.label,
                onClick: c.onClick
              }))
          }))}
        />
      </Sider>
      <Layout>
        <Content className="main-content">
          <NavRoutes />
        </Content>
        <Footer>Organizator Zubne Tehnike Admin ©2023</Footer>
      </Layout>
    </Layout>
  );
}

export default AppContent;
