import { createContext, useState, useEffect } from "react";
import { debounce } from "lodash";

export const GlobalContext = createContext({
  deviceType: "pc",
});

const GlobalContextProvider = ({ children }) => {
  const [deviceType, setDeviceType] = useState(getDeviceType());

  useEffect(() => {
    // Add event listener to detect window resize and update deviceType
    const handleResize = debounce(() => {
      setDeviceType(getDeviceType());
    }, 200);

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to determine the current device type based on window width
  function getDeviceType() {
    const width = window.innerWidth;
    if (width < 768) {
      return "mobile";
    } else if (width < 992) {
      return "tablet";
    } else {
      return "pc";
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        deviceType,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
