import moment from "moment";
import "moment/locale/sr";
import { permissionMap } from "./permissions";
moment.locale("sr");

export function formatDate(dateString) {
  return moment(dateString).format("D. MMM YYYY.");
}
export function formatDateTime(dateString) {
  return moment(dateString).format("D. MMM YYYY. HH:mm");
}

export const unpackPermissionsFromString = packedPermissions => {
  if (!packedPermissions) return [];

  const permissions = [];
  for (let i = 0; i < packedPermissions.length; i++) {
    const code = packedPermissions.charCodeAt(i);
    permissions.push(code);
  }

  return permissions.map(p => permissionMap[p]);
};
