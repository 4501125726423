import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../components/auth/Login";
import Logout from "../components/auth/Logout";
import PrivateRoute from "./PrivateRoute";

import UserList from "../pages/Users";
import Labs from "../pages/Labs";
import FindFirebaseUser from "../pages/FindFirebaseUser";
import UserProfile from "../pages/UserProfile";
import Lab from "../pages/Lab";
import Cases from "../pages/Cases";

const NavRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route element={<PrivateRoute />}>
        <Route path="/labs" element={<Labs />} />
        <Route path="/lab/:id" element={<Lab />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/user/:email" element={<UserProfile />} />
        <Route path="/fb-users" element={<FindFirebaseUser />} />
        <Route path="/cases" element={<Cases />} />
      </Route>
      <Route path="*" element={<Navigate to="/labs" replace />} />
    </Routes>
  );
};

export default NavRoutes;
