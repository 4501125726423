import { Table, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { formatDate, unpackPermissionsFromString } from "../helpers";

const defaultExpandable = {
  expandedRowRender: record => (
    <p>
      {unpackPermissionsFromString(record.permissions).map(p => (
        <p>{p}</p>
      ))}
    </p>
  )
};

const UsersInLabTable = ({ data }) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: date => {
        return formatDate(date);
      }
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName"
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: text => <p className="table-main">{text}</p>
    },
    {
      title: "Role",
      dataIndex: "roleId",
      key: "roleId"
    },
    {
      title: "Settings",
      dataIndex: "settings",
      key: "settings"
    },
    {
      title: "",
      key: "actions",
      render: (text, record) => (
        <>
          <Button onClick={() => navigate("/user/" + record.email)}>
            View
          </Button>
        </>
      )
    }
  ];

  return (
    <>
      <Table
        rowKey="id"
        dataSource={data}
        columns={columns}
        expandable={defaultExpandable}
      />
    </>
  );
};

export default UsersInLabTable;
