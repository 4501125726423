export const Permissions = {
  NotSet: 0,
  AccessAll: 1,

  CasesTableRead: 10,
  DashboardRead: 11,
  CalendarRead: 12,
  DoctorsTableRead: 13,
  InvoicesTableRead: 15,
  ProductionLogsTableRead: 16,
  StatsRead: 17,

  DoctorNameRead: 20,
  PatientNameRead: 21,

  CaseCreate: 30,
  CaseRead: 31,
  CaseUpdate: 32,
  CaseDelete: 33,

  ProductionLogCreate: 40,
  ProductionLogRead: 41,
  ProductionLogUpdate: 42,
  ProductionLogDelete: 43,

  TryoutCreate: 50,
  TryoutRead: 51,
  TryoutUpdate: 52,
  TryoutDelete: 53,

  DoctorCreate: 60,
  DoctorRead: 61,
  DoctorUpdate: 62,
  DoctorDelete: 63,

  InvoiceCreate: 80,
  InvoiceRead: 81,
  InvoiceUpdate: 82,
  InvoiceDelete: 83,

  PaymentsCreate: 90,
  PaymentsRead: 91,
  PaymentsUpdate: 92,
  PaymentsDelete: 93,

  EmployeeRead: 101,
  EmployeeUpdate: 102,
  EmployeeDelete: 103,

  LabUpdate: 112,
  LabDelete: 113,

  InvitationsCreate: 120,
  InvitationsRead: 121,
  InvitationsDelete: 123,

  TaskCreate: 130,
  TaskRead: 131, // todo, check do we need it
  TaskUpdate: 132,
  TaskDelete: 133,

  ProductsCreate: 140,
  ProductsRead: 141,
  ProductsUpdate: 142,
  ProductsDelete: 143,

  SchedulingTemplateCreate: 150,
  SchedulingTemplateRead: 151,
  SchedulingTemplateUpdate: 152,
  SchedulingTemplateDelete: 153,

  SchedulingCreate: 160,
  SchedulingRead: 161,
  SchedulingUpdate: 162,
  SchedulingDelete: 163,

  SubscriptionsCreate: 170,
  SubscriptionsRead: 171,
  SubscriptionsUpdate: 172,
  SubscriptionsDelete: 173,

  MaterialsCreate: 180,
  MaterialsRead: 181,
  MaterialsUpdate: 182,
  MaterialsDelete: 183
};

export const permissionMap = Object.keys(Permissions).reduce((result, key) => {
  const value = Permissions[key];
  result[value] = key;

  return result;
}, {});
