import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC2tryHwbBvAnHJBerk34_VRxfE5f0cgig",
  authDomain: "auth.dentallabguru.com",
  databaseURL: "https://dtlab-81763.firebaseio.com",
  projectId: "dtlab-81763",
  storageBucket: "dtlab-81763.appspot.com",
  messagingSenderId: "115359777579",
  appId: "1:115359777579:web:eb2810e4b5351551943b6e",
  measurementId: "G-Y0VD56SHF5"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
