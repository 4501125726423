import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const ValueLabel = ({ label, children }) => {
  return (
    <div>
      <Text>{label}: </Text>
      <Text strong>{children}</Text>
    </div>
  );
};

export default ValueLabel;
