import React from "react";

import AppContent from "./components/layout/AppContent";
import { AuthProvider } from "./context/AuthContext";
import GlobalContextProvider from "./context/GlobalContext";

const App = () => {
  return (
    <AuthProvider>
      <GlobalContextProvider>
        <AppContent />
      </GlobalContextProvider>
    </AuthProvider>
  );
};

export default App;
