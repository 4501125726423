import { auth } from "./firebase";
import { notification } from "antd";

// const baseUrl = "http://localhost:3001/api/";
const baseUrl = "https://api-nwjfm2xnca-uc.a.run.app/api/";
export const get = async (url, reqData) => {
  try {
    const user = auth.currentUser;

    let getUrl = baseUrl + url;
    if (reqData) getUrl += "?" + new URLSearchParams(reqData).toString();
    if (user) {
      const token = await user.getIdToken();
      const response = await fetch(getUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = await response.json();
      return data;
    } else {
      // Handle case where user is not logged in
      return null;
    }
  } catch (error) {
    console.error("Error performing GET request:", error);
    notification.error({
      message: "Error",
      description:
        "An error occurred while performing the GET request." + error.message
    });
    throw error;
  }
};

export const del = async url => {
  try {
    const user = auth.currentUser;

    if (user) {
      const token = await user.getIdToken();
      const response = await fetch(baseUrl + url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const responseData = await response.json();
      return responseData;
    } else {
      // Handle case where user is not logged in
      return null;
    }
  } catch (error) {
    console.error("Error performing GET request:", error);
    notification.error({
      message: "Error",
      description:
        "An error occurred while performing the GET request." + error.message
    });
    throw error;
  }
};

export const post = async (url, data) => {
  try {
    const user = auth.currentUser;

    if (user) {
      const token = await user.getIdToken();
      const response = await fetch(baseUrl + url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      return responseData;
    } else {
      // Handle case where user is not logged in
      return null;
    }
  } catch (error) {
    console.error("Error performing POST request:", error);
    notification.error({
      message: "Error",
      description:
        "An error occurred while performing the POST request." + error.message
    });
    throw error;
  }
};
