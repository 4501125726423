import React, { createContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { get } from "../api";
import { Skeleton } from "antd";

export const AuthContext = createContext({
  currentUser: null
});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        // ensure that user had the correct token
        get("users/customClaims")
          .then(res => {
            setCurrentUser({
              name: user.displayName,
              email: user.email
            });
            setLoading(false);
          })
          .catch(function (error) {
            // Handle error
            signOut(auth);
            console.error(error, "customClaims error");
            setLoading(false);
          });
      } else {
        // User is signed out
        // ...
        console.log("logged out");

        setCurrentUser(null);
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
