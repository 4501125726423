import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = ({ redirectPath = "/login" }) => {
  const { currentUser } = useContext(AuthContext);

  if (currentUser) return <Outlet />;
  return <Navigate to={redirectPath} replace />;
};

export default PrivateRoute;
