import { Table } from "antd";
import { formatDate } from "../helpers";

const DoctorsTable = ({ data }) => {
  const columns = [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: date => {
        return formatDate(date);
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Clinic Name",
      dataIndex: "clinicName",
      key: "clinicName"
    }
  ];

  return (
    <>
      <Table rowKey="id" dataSource={data} columns={columns} />
    </>
  );
};

export default DoctorsTable;
