import { useEffect, useState } from "react";
import { Table, Input, Button, Skeleton } from "antd";
import { get } from "../api";
import MyPageHeader from "../components/layout/MyPageHeader";
import { formatDate } from "../helpers";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

const Labs = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  useEffect(() => {
    fetchData();
  }, [pagination.current, searchText]);

  const fetchData = () => {
    setLoading(true);
    get("labs", {
      ...pagination,
      search: searchText
    })
      .then(response => {
        setData(response.results);
        setPagination(p => ({
          ...p,
          total: response.totalCount
        }));
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleTableChange = pagination => {
    setPagination(pagination);
  };

  const columns = [
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: date => {
        return formatDate(date);
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: text => <p className="table-main">{text}</p>
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency"
    },
    {
      title: "",
      key: "actions",
      render: (text, record) => (
        <Button onClick={() => navigate(`/lab/${record.id}`)}>View</Button>
      )
    }
  ];

  return (
    <>
      <MyPageHeader key="ph" title={"Labs"} />
      <Search
        placeholder="Search labs"
        onChange={debounce(e => {
          setPagination(p => ({ ...p, current: 1 }));
          setSearchText(e.target.value);
        }, 300)}
      />
      <Skeleton loading={loading}>
        <Table
          rowKey="id"
          dataSource={data}
          columns={columns}
          pagination={{
            ...pagination,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
          }}
          onChange={handleTableChange}
        />
      </Skeleton>
    </>
  );
};

export default Labs;
